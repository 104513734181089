import React from "react";
import "./style/preloader.scss";

const Preloader = () => {
  return (
    <div className="loader">
      <div className="loader-wipper-svg">
        <svg
          width="150"
          height="200"
          viewBox="0 0 187.3 93.7"
          preserveAspectRatio="xMidYMid meet"
        //   style={{
        //     left: "50%",
        //     top: "50%",
        //     position: "absolute",
        //     transform: "translate(-50%, -50%),matrix(1, 0, 0, 1, 0, 0)",
        //   }}
        >
          {/* Define a linear gradient */}
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop stop-color="#00E5FF" />
              <stop offset="0.12" stop-color="#29B8FE" />
              <stop offset="0.29" stop-color="#5E81FD" />
              <stop offset="0.46" stop-color="#8952FD" />
              <stop offset="0.62" stop-color="#AB2EFC" />
              <stop offset="0.76" stop-color="#C415FC" />
              <stop offset="0.9" stop-color="#D205FC" />
              <stop offset="1" stop-color="#D800FC" />
            </linearGradient>
          </defs>

          {/* Use the linear gradient as a stroke for your path */}
          <path
            stroke="url(#gradient)"
            id="outline"
            fill="none"
            stroke-width="10"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          />

          <path
            id="outline-bg"
            opacity="0.05"
            fill="none"
            stroke="#ededed"
            stroke-width="10"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          />
        </svg>
      </div>
    </div>
  );
};

export default Preloader;
