import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../styles/contact.css";
import "../styles/button.css";
import ContactMobile from "../assets/images/contact-mobile.svg";
import { FaMapMarkerAlt, FaEnvelopeOpenText } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import Aos from 'aos';
import "aos/dist/aos.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SlideBtn from '../components/slidebtn/SlideBtn';
import Header from '../components/header/Header';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
     name : "",
     email : "",
     message: ""
  })

  const handleChange = (event) =>{
    const {name, value} = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleSubmit = (event)=>{
    event.preventDefault();

    axios.post('https://procohat-nodemailer-h6jjrgjdx-aneesh-b-design.vercel.app/send_email',formData)
    .then(()=> {console.log(formData)}).then(()=>{
      toast.success('Message sent successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });

        setFormData({
          name: '',
          email: '',
          message: ''
        })
    })
    

    
  }
  useEffect(()=> {
    Aos.init({
      duration: 2000,
      offset: 100,
    });
  },[])
  return (
    <>
    <Header />
    <section className='contact-section'>
    <div className="bg-light-1"></div>
      <div className="contact-container">
        <div className="contact-medium-content">
        <div className="contact-right">
          <div className="contact-right-content">
            <h1 data-aos="zoom-out-down" data-aos-easing="ease"> Get in Touch </h1>
            <p data-aos="zoom-in-up" data-aos-easing="ease" data-aos-delay="500">We' love to hear from you , Our friendly team is always here to chat .</p>
            <img src={ContactMobile} alt="ContactMobile" className='image-show-mobile' />
            <form action="" method='Post' onSubmit={handleSubmit}>
              <label htmlFor="name">Your Name</label>
              <input 
              type="text" 
              name="name" 
              id="name"      
              autoComplete="off"
              required 
              value = {formData.name}
              placeholder='Full Name'
              onChange = {handleChange} />       
              <label htmlFor="email">Your Email</label>
              <input 
              type="email" 
              name="email" 
              id="email" 
              autoComplete="off" 
              required 
              value = {formData.email}
              placeholder='Email'
              onChange = {handleChange} />
              <label htmlFor="message">Your Message</label>
              <textarea 
              name="message" 
              id="message" 
              cols="30" 
              rows="6"
              value = {formData.message}
              placeholder='Your  Message'
              onChange = {handleChange}/>
              <button type="submit" className='btn btn-message'>Send Message<i class="bi bi-chevron-right"></i></button>
            </form>
          </div>
        </div>
        <div className="contact-left">
            <div className="contact-left-content">
              <img src={ContactMobile} alt="ContactMobile" className='image-show-mobile' />
              <div className='contact-details pt-5'>
                <div className="address contact-details-items" data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1200">
                 <FaMapMarkerAlt />
                 <p>Plot No, 20, IT Park Rd, Parsodi,Nagpur, Maharashtra 440022</p>
                </div>
                <div className="mobile contact-details-items" data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1400">
                  <FaPhoneVolume />
                   <a href="tel:+91 92843 84585"><p>+91 92843 84585</p></a>
                </div>
                <div className="mail contact-details-items" data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1600">
                <FaEnvelopeOpenText />
                   <a href="mailto:info@procohat.com"><p>info@procohat.com</p></a>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
    </section>
    <section className='contact-map'>
    <div className="bg-light-2"></div>
      <div className="map-container">
      <iframe title='ContactMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1421.2961923089426!2d79.06222486002981!3d21.110709760639804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bf1d44b971bb%3A0xaf3f5fb1ec5c7ea4!2sProCohat%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1693320663698!5m2!1sen!2sin" width="100%" height="450" style= {{border: 0 }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </section>
    <ToastContainer />
    <SlideBtn />
    </>
  );
}

export default Contact
