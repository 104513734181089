import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Lottie from "lottie-react";
// import webApplication from "../assets/lottie/web-application.json";
import "../styles/services.css";
import "../styles/button.css";
import ColorLine from "../assets/images/arrow-line.png";
import Triangle from "../assets/images/triangle-1.svg";
import Web_Application from "../assets/images/web-application-1.png";
import Mobile_Application from "../assets/images/mobile-application-1.png";
import Custom_developement from "../assets/images/custom-development-1.png";
import Business from "../assets/images/business-1.png";
import Cloud from "../assets/images/cloud-1.png";
import Aos from "aos";
import "aos/dist/aos.css";
import SlideBtn from "../components/slidebtn/SlideBtn";
import Header from "../components/header/Header";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 100,
    });
  }, []);
  return (
    <>
      <Header />
      <section className="service-main">
        <div className="arrow-line">
          <img
            src={ColorLine}
            alt=""
            data-aos="slide-left"
            data-aos-easing="ease"
          />
        </div>
        <div className="container">
          <div className="service-content">
            <h1
              className="service-heading"
              data-aos="zoom-out"
              data-aos-easing="ease"
              data-aos-delay="1000"
            >
              Our services
            </h1>
            <p
              className="serivce-text"
              data-aos="zoom-in"
              data-aos-delay="1500"
              data-aos-easing="ease"
            >
              Our custom services are meticulously tailored to address our
              clients' unique needs, with a primary focus on our cutting-edge
              SaaS product offerings. From streamlining data processing to
              developing innovative applications, including ERP solutions, we
              provide flexible options—whether onshore, offshore, onsite, or a
              blend of these approaches. Leveraging the wealth of collected
              data, ProCohat delivers expert services designed to optimize
              performance and drive your business forward.
            </p>
            <div className="angle">
              <img
                src={Triangle}
                alt=""
                data-aos="rotate"
                data-aos-easing="ease"
                data-aos-delay="500"
              />
            </div>
            <Link to="/contact">
              <button
                className="btn btn-touch"
                data-aos="fade-up"
                data-aos-delay="2000"
                data-aos-easing="ease"
              >
                Get in touch <i class="bi bi-chevron-right"></i>
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="service-content">
        <div className="web-application service-container">
          <div className="web-application-box box">
            <div className="web-application-image box-image">
              <img
                className="image"
                src={Web_Application}
                alt="Web Application"
              />
              {/* <Lottie animationData={webApplication} /> */}
            </div>
            <div className="web-application-content box-content">
              <h2 data-aos="fade-left" data-aos-easing="ease">
                Web Applications
              </h2>
              <h3
                data-aos="fade-left"
                data-aos-easing="ease"
                data-aos-delay="1000"
              >
                Enhance Your Business Competitiveness
              </h3>
              <ul
                style={{ listStyleType: "disc", color: "#fff" }}
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="1200"
              >
                <li>
                   Custom web development and third-party platform integration.
                </li>
                <br></br>
                <li> Effective, intuitive, and flexible designs.</li>
                <br></br>
                <li> Mobile-adaptive for seamless user experiences.</li>
                <br></br>
                <li>
                   Usability expertise turning client ideas into polished
                  products.
                </li>
                <br></br>
                <li>
                   Seamless integration for enhanced efficiency in your
                  enterprise ecosystem.
                </li>
              </ul>
            </div>
          </div>
          <div className="web-application-box-btn service-box-btn">
            <div className="btn-row row">
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Static Websites</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="200"
                data-aos-anchor-placement="forwards"
              >
                <span> Maintenance </span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="400"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Integration</span>
              </button>
              <button
                className="btn btn-service btn-tablet-hide"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Migration & Transfer</span>
              </button>
            </div>
            <div className="btn-row row">
              <button
                className="btn btn-service btn-tablet-show"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Migration & Transfer</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="800"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Custom E-Commerce Web pages</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">
                  SSL Certificate and Security
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="mobile-application service-container">
          <div className="mobile-application-box box">
            <div className="mobile-application-image box-image">
              <img
                className="image"
                src={Mobile_Application}
                alt="Mobile Application"
              />
            </div>
            <div className="mobile-application-content box-content">
              <h2 data-aos="fade-left" data-aos-easing="ease">
                Mobile Applications
              </h2>
              <h3
                data-aos="fade-left"
                data-aos-easing="ease"
                data-aos-delay="1000"
              >
                Innovation-driven Future-ready Apps
              </h3>
              <p
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="1200"
              >
                <ul>
                  <li>Build tech-efficient mobile applications.</li>
                  <br></br>
                  <li>Feature-rich mobile apps boost visibility and lead conversion.</li>
                  <br></br>
                  <li>Innovation-led solutions for business landscape reimagining.</li>
                  <br></br>
                  <li>Seamless integration enhances efficiency in enterprise ecosystems.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="mobile-application-box-btn service-box-btn">
            <div className="btn-row row">
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">
                  20+ Mobile Applications Developed
                </span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="200"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">
                  5 Years of Industry Experience
                </span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="400"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">App Customization</span>
              </button>
              <button
                className="btn btn-service btn-tablet-hide"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Agile Method Approach</span>
              </button>
            </div>
            <div className="btn-row row">
              <button
                className="btn btn-service btn-tablet-show"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Agile Method Approach</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="800"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Post Development Support</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Affordable Pricing</span>
              </button>
            </div>
          </div>
        </div>
        <div className="sunlight-brightnes-service-1"></div>
        <div className="custom-developement service-container">
          <div className="custom-developement-box box">
            <div className="custom-developement-image box-image">
              <img className="image" src={Custom_developement} alt="" />
            </div>
            <div className="custom-developement-content box-content">
              <h2 data-aos="fade-left" data-aos-easing="ease">
                Custom Developed System
              </h2>
              <h3
                data-aos="fade-left"
                data-aos-easing="ease"
                data-aos-delay="1000"
              >
                Designed to Master your Industry Challenges
              </h3>
              <p
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="1200"
              >
                <ul>
                  <li>Strategic development plan aligns with client vision and end-user needs.</li>

                  <br></br>

                  <li>Creative ideations, proven methods, and innovative technologies drive our approach.</li>

                  <br></br>

                  <li>Expertise extends beyond custom software to include app integration, usability & design assistance, and process evaluation.</li>

                  <br></br>

                  <li>Continuous adaptation to meet evolving client and partner requirements.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="custom-developement-box-btn service-box-btn">
            <div className="btn-row row">
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Discovery</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="200"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Research</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="400"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Prototyping</span>
              </button>
              <button
                className="btn btn-service btn-tablet-hide"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Development</span>
              </button>
            </div>
            <div className="btn-row row">
              <button
                className="btn btn-service btn-tablet-show"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Development</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="800"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Testing</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Handholding & Go Live</span>
              </button>
            </div>
          </div>
        </div>
        <div className="business-solution service-container">
          <div className="business-solution-box box">
            <div className="business-solution-image box-image">
              <img className="image" src={Business} alt="Business" />
            </div>
            <div className="business-solution-content box-content">
              <h2 data-aos="fade-left" data-aos-easing="ease">
                Business Resource Outsourcing
              </h2>
              <h3
                data-aos="fade-left"
                data-aos-easing="ease"
                data-aos-delay="1000"
              >
                Experience the Digital Transformation
              </h3>
              <p
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="1200"
              >
               <ul>
                <li>Centralizes all data into one unified system for streamlined operations.</li>

                <br></br>

                <li>Acts as a single-source platform, providing real-time visibility and functional insights.</li>

                <br></br>

                <li>ERP solutions automate operations and offer real-time insights.</li>

                <br></br>

                <li>Enhances productivity, decision-making, and strategic alignment.</li>
               </ul>
              </p>
            </div>
          </div>
          <div className="business-solution-box-btn service-box-btn">
            <div className="btn-row row">
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Static Website</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="200"
                data-aos-anchor-placement="forwards"
              >
                <span> Maintenance </span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="400"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Integration</span>
              </button>
              <button
                className="btn btn-service btn-tablet-hide"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Migration & Transfer</span>
              </button>
            </div>
            <div className="btn-row row">
              <button
                className="btn btn-service btn-tablet-show"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Migration & Transfer</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="800"
                data-aos-anchor-placement="forwards"
              >
                <span className="text-custom">
                  {" "}
                  Custom E-Commerce Web pages
                </span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-anchor-placement="forwards"
              >
                <span className="text-custom">
                  SSL Certificate and Security
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="sunlight-brightnes-service-2"></div>
        <div className="cloud-computing service-container">
          <div className="cloud-computing-box box">
            <div className="cloud-computing-image box-image">
              <img className="image" src={Cloud} alt="" />
            </div>
            <div className="cloud-computing-content box-content">
              <div className="cloud-computing-content box-content">
                <h2 data-aos="fade-left" data-aos-easing="ease">
                  Cloud Computing
                </h2>
                <h3
                  data-aos="fade-left"
                  data-aos-easing="ease"
                  data-aos-delay="1000"
                >
                  Unleash the Power of Seamless Connectivity
                </h3>
                <p
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="1200"
                >
                 <ul>
                  <li>Unlock seamless digital transformation with our Cloud Computing solutions.</li>

                  <br></br>

                  <li>Our unified platform streamlines operations, providing real-time insights.</li>

                  <br></br>
                  <li>Embrace the future of technology where Cloud Computing meets innovation.</li>

                  <br></br>
                 </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="cloud-computing-box-btn service-box-btn">
            <div className="btn-row row">
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Static Websites</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="200"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span> Maintenance </span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="400"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Integration</span>
              </button>
              <button
                className="btn btn-service btn-tablet-hide"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Migration & Transfer</span>
              </button>
            </div>
            <div className="btn-row row">
              <button
                className="btn btn-service btn-tablet-show"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-aos-anchor-placement="forwards"
              >
                {" "}
                <span className="text-custom">Migration & Transfer</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="800"
                data-aos-anchor-placement="forwards"
              >
                <span className="text-custom">Custom E-Commerce Web pages</span>
              </button>
              <button
                className="btn btn-service"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-anchor-placement="forwards"
              >
                <span className="text-custom">
                  SSL Certificate and Security
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <SlideBtn />
    </>
  );
};

export default Services;
