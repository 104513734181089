import React, { useEffect, useState, useRef } from "react";
import "./style/services.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { LuCode2 } from "react-icons/lu";
import PenIcon from "../../../assets/images/pen-icon.svg";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

const ServiceCard = ({ icon: Icon, title, description, imgSrc }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;

    const handleMouseMove = (e) => {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setPosition({ x, y });
    };

    card.addEventListener("mousemove", handleMouseMove);

    return () => {
      card.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="service-card p-10"
      ref={cardRef}
      style={{ "--x": `${position.x}px`, "--y": `${position.y}px` }}
    >
      <div className="service-card-content w-full flex flex-col items-start justify-center z-50">
        <div className="service-card-icon">
          <div className="icon text-[#D3D3D3] text-[40px] w-[50px] h-[50px] bg-[#FFFFFF26] rounded-[50%] relative">
            {Icon ? (
              <Icon className="absolute right-[-10px] top-[-5px]" />
            ) : (
              <img
                src={imgSrc}
                alt="Icon"
                className="absolute right-[0] top-[-6px]"
              />
            )}
          </div>
        </div>
        <div className="service-card-text mt-5">
          <div className="text-heading">
            <h3 className="text-[24.15px] font-bold text-[#C8C8C8]">{title}</h3>
          </div>
          <div className="text-paragraph mt-5">
            <p className="text-[18.93px] text-[#BCBCBC]">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Services = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 100,
    });
  }, []);

  const servicesData = [
    {
      icon: LuCode2,
      title: "SAAS/ Development",
      description:
        "SaaS/Development We specialize in crafting robust and scalable Software-as-a-Service (SaaS) solutions tailored to your unique business needs.",
    },
    {
      imgSrc: PenIcon,
      title: "Data Analysis",
      description:
        "Data Analytics Unlock the hidden potential of your data with our comprehensive data analytics services. Our data experts transform raw information into actionable insights that drive informed decision-making.",
    },
    {
      imgSrc: PenIcon,
      title: "ServiceNow",
      description:
        "ServiceNow Streamline your IT operations and enhance service delivery with our ServiceNow expertise. We offer implementation, customization, and development services to optimize your ServiceNow platform.",
    },
    {
      imgSrc: PenIcon,
      title: "AI-ML",
      description:
        "AI/ML Embrace the power of artificial intelligence and machine learning with our cutting-edge solutions.",
    },
    {
      imgSrc: PenIcon,
      title: "Cloud-based BI Solutions",
      description:
        "Cloud-Based BI Solutions Access real-time insights and make data-driven decisions with our cloud-based business intelligence solutions. ",
    },
    {
      imgSrc: PenIcon,
      title: "Design",
      description:
        "Design Elevate your brand and captivate your audience with our exceptional design services. Our creative team delivers visually stunning designs that align with your business objectives. ",
    },
  ];

  return (
    <>
      <section className="service-new-section w-full flex flex-col justify-center items-center py-12">
        <div className="service-new-container w-full  flex flex-col justify-center items-center max-w-[1200px]">
          <div className="service-heading-content w-full flex flex-row justify-between items-center pr-10">
            <div className="heading-1 w-[60%] flex flex-col">
              <h4
                className="text-[#5C5C5C] text-[36.02px]"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                What we Do
              </h4>
              <h2
                className="text-[76.93px] text-white"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                ProCohat for
              </h2>
              <h2
                className="text-[#A74AFF] text-[76.93px]"
                data-aos="fade-up"
                data-aos-delay="900"
              >
                Digital Platforms
              </h2>
            </div>
            <div className="heading-2 w-[40%]">
              <h2 className="text-[143.42px] font-normal">SERVICES</h2>
            </div>
          </div>
          <div className="service-new-card w-full pt-16 flex justify-center items-center flex-col">
            <div className="service-card-container w-full">
              {servicesData.map((service, index) => (
                <ServiceCard
                  key={index}
                  icon={service.icon}
                  imgSrc={service.imgSrc}
                  title={service.title}
                  description={service.description}
                />
              ))}
            </div>
            <div
              className="service-new-card-button-new w-full mt-10 flex flex-col justify-center items-center"
              data-aos="fade-up"
              data-aos-delay="1200"
            >
              <Link to="/services" className="w-auto">
                <button className="text-white hover:text-white py-3 pl-8 flex items-center justify-between gap-3 relative">
                  More Services{" "}
                  <FaArrowRight className="bg-white absolute right-[-10px] w-[60px] h-[60px] rounded-[50%] text-[20px] p-4 text-[#6E24B5]" />
                </button>{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
